import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const userRoles = JSON.parse(sessionStorage.getItem("user_Roles"));
  const userDetails = JSON.parse(sessionStorage.getItem("user_details"));

  return (
    <div>
      {/* //  Custom code starts */}
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal"
      >
        {/* Home Menu */}
        {userRoles.some((role) => role.id === 9) ? (<></>) : (<SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="main"
          title="Home"
          className="scale-x"
        >
          <Menu.Item key="dashboard/employee">
            <Link to="/dashboard/employee" className="scale-x">
              <i className="icon icon-user-o" />
              <span>Employee</span>
            </Link>
          </Menu.Item>
        </SubMenu>)}

        {/* Log Efforts Menu */}
        {userRoles.some((role) => role.id === 9) ? (<></>) : (<SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="extensions"
          title="Log Efforts"
          className="scale-x"
        >
          <Menu.Item key="timesheets/submit">
            <Link to="/timesheets/submit" className="scale-x">
              <i className="icon icon-timepicker" />
              <span>Submit</span>
            </Link>
          </Menu.Item>
        </SubMenu>)}

        {/* For Time Sheet Approval */}
        {userDetails.isReportingManager ? (<SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="Resource-Manager"
          title="Resource Manager"
          className="scale-x"
        >
          <Menu.Item key="dashboard/reportingmanager/projects">
            <Link to="/dashboard/reportingmanager/projects" className="scale-x">
              <i className="icon icon-home" />
              <span>Dashboard - Projects</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="dashboard/reportingmanager/tasks">
            <Link to="/dashboard/reportingmanager/tasks" className="scale-x">
              <i className="icon icon-home" />
              <span>Dashboard - Tasks</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="timesheets/rmapprove">
            <Link to="/timesheets/rmapprove" className="scale-x">
              <i className="icon icon-check-circle-o" />
              <span>RM Approve</span>
            </Link>
          </Menu.Item>
        </SubMenu>) : ("")}

        {/* Role No:3, Project Manager Role Menu */}
        {userRoles.some((role) => role.id === 3) ? (
          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="project-manager"
            title="Project Manager"
            className="scale-x"
          >
            <Menu.Item key="dashboard/manager">
              <Link to="/dashboard/manager" className="scale-x">
                <i className="icon icon-home" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="tasks/manage">
              <Link to="/tasks/manage" className="scale-x">
                <i className="icon icon-tasks" />
                <span>Task</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="dashboard/manager/reports/timelog/index">
              <Link to="/dashboard/manager/reports/timelog/index" className="scale-x">
                <i className="icon icon-translation" />
                <span>Timelog Summary</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="weeklystatus/manage">
              <Link to="/weeklystatus/manage" className="scale-x">
                <i className="icon icon-select" />
                <span>WSR</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="resources/manage">
              <Link to="/resources/manage" className="scale-x">
                <i className="icon icon-wall" />
                <span>Resources</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="timesheets/approve">
              <Link to="/timesheets/approve" className="scale-x">
                <i className="icon icon-check-circle-o" />
                <span>PM Approve</span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="plan/manage">
              <Link to="/plan/manage">
                <i className="icon icon-home" />
                <span>Plan</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="issues/manage">
              <Link to="/issues/manage">
                <i className="icon icon-home" />
                <span>Issues</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="cost/manage">
              <Link to="/cost/manage">
                <i className="icon icon-home" />
                <span>Cost</span>
              </Link>
            </Menu.Item> */}
          </SubMenu>
        ) : ("")}

        {/* Role No:2, PMO Role Menu */}
        {userRoles.some((role) => role.id === 2) ? (<SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="pmo"
          title="PMO"
          className="scale-x"
        >
          <Menu.Item key="master/projects/manage">
            <Link to="/master/projects/manage" className="scale-x">
              <i className="icon icon-home" />
              <span>Projects</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="master/tasks/manage">
            <Link to="/master/tasks/manage?module=PMO" className="scale-x">
              <i className="icon icon-tasks" />
              <span>Tasks</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="reports/timesheet/index">
            <Link to="/reports/timesheet/index" className="scale-x">
              <i className="icon icon-translation" />
              <span>WeeklyTsStatus</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="reports/timesheet/timelog">
            <Link to="/reports/timesheet/timelog" className="scale-x">
              <i className="icon icon-translation" />
              <span>Timelog Summary</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="plan/manage">
            <Link to="/plan/manage" className="scale-x">
              <i className="icon icon-translation" />
              <span>Plan</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="weeklystatus/manage">
            <Link to="/weeklystatus/manage" className="scale-x">
              <i className="icon icon-select" />
              <span>WSR</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="issues/manage">
            <Link to="/issues/manage" className="scale-x">
              <i className="icon icon-error" />
              <span>Issues</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="resources/manage">
            <Link to="/resources/manage" className="scale-x">
              <i className="icon icon-wall" />
              <span>Resources</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="cost/manage">
            <Link to="/cost/manage" className="scale-x">
              <i className="icon icon-pricing-table" />
              <span>Cost</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="skillMgr">
            <Link to="/skillMgr?module=PMO" className="scale-x">
              <i className="icon icon-graduation" />
              <span>Skills</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="orgstructure/manage">
            <Link to="/orgstructure/manage" className="scale-x">
              <i className="icon icon-chart-tree" />
              <span>Org Structure</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="master/projectresource">
            <Link to="/master/projectresource" className="scale-x">
              <i className="icon icon-home" />
              <span>Project Resource</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="reset/userpasswordbypmo">
            <Link to="/reset/userpasswordbypmo" className="scale-x">
              <i className="icon icon-reset-password" />
              <span>Reset Password</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="timesheet/statuschange">
            <Link to="/timesheet/statuschange" className="scale-x">
              <i className="icon icon-timepicker" />
              <span>Reject Timesheet</span>
            </Link>
          </Menu.Item>
        </SubMenu>) : ("")}

        {/* Executive */}
        {userRoles.some((role) => role.id === 9999) ? (<SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="executive"
          title="Executive"
          className="scale-x"
        >
          <Menu.Item key="master/projects/manage">
            <Link to="/master/projects/manage" className="scale-x">
              <i className="icon icon-diamond" />
              <span>Projects</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="master/tasks/manage">
            <Link to="/master/tasks/manage?module=Executive" className="scale-x">
              <i className="icon icon-tasks" />
              <span>Tasks</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="master/clients/manage">
            <Link to="/master/clients/manage" className="scale-x">
              <i className="icon icon-company" />
              <span>Clients</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="skillMgr">
            <Link to="/skillMgr?module=Executive" className="scale-x">
              <i className="icon icon-graduation" />
              <span>Skills</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="master/orgstructure/manage">
            <Link to="/master/orgstructure/manage" className="scale-x">
              <i className="icon icon-family" />
              <span>Org Structure</span>
            </Link>
          </Menu.Item>
        </SubMenu>) : ("")}

        {/* Travel Desk Menu */}
        {userRoles.some((role) => role.id === 9) ? (<></>) : (<SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="travel_desk"
          title="Travel Desk" className="scale-x">
          <Menu.Item key="travel_desk/request">
            <Link to="/travel_desk/request" className="scale-x">
              <i className="icon icon-hotel-booking" />
              <span>Travel Request</span>
            </Link>
          </Menu.Item>
          {userRoles.some((role) => role.id === 3) &&
            <Menu.Item key="travel_desk/pm-approve">
              <Link to="/travel_desk/pm-approve" className="scale-x">
                <i className="icon icon-check-circle-o" />
                <span>PM Approve</span>
              </Link>
            </Menu.Item>}
          {userDetails.isReportingManager &&
            <Menu.Item key="travel_desk/rm-approve">
              <Link to="/travel_desk/rm-approve" className="scale-x">
                <i className="icon icon-check-circle-o" />
                <span>RM Approve</span>
              </Link>
            </Menu.Item>}
          {userRoles.some((role) => role.id === 8) &&
            <Menu.Item key="travel_desk/admin-approve">
              <Link to="/travel_desk/admin-approve" className="scale-x">
                <i className="icon icon-check-circle-o" />
                <span>Admin Approve</span>
              </Link>
            </Menu.Item>}
        </SubMenu>)}

        {/* Customer Menu 
        {userRoles.some((role) => role.id === 9) ? (<SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="Resource-Manager"
          title="Home"
          className="scale-x"
        >
          <Menu.Item key="dashboard/customer">
            <Link to="/dashboard/customer" className="scale-x">
              <i className="icon icon-home" />
              <span>Dashboard</span>
            </Link>
          </Menu.Item>
        </SubMenu>) : ("")*/}
      </Menu>

    </div>

    //Custom code ends.

  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;