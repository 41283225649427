import React, { useEffect, useState } from "react";
import axios from 'axios';

import { Row, Col, Form, Input, Select, Radio, DatePicker, Button, message, Divider, Breadcrumb } from "antd";
import { API_URL } from 'constants/APIConfig';
import moment from "moment";
import { useHistory } from "react-router-dom";
import Widget from "components/Widget/index";
const FormItem = Form.Item;
const { Option } = Select;
const RadioGroup = Radio.Group;

function handleChange(value) {
    console.log(`selected ${value}`);
}
function handleBlur() {
    console.log('blur');
}

function handleFocus() {
    console.log('focus');
}

const EditResource = (props) => {
    const token = sessionStorage.getItem('token');
    const [form] = Form.useForm();
    const [locations, setLocations] = useState([]);
    const [users, setUsers] = useState([]);

    const [isRM, setIsRM] = useState(0);
    const [isCM, setIsCM] = useState(0);
    const [isActive, setIsActive] = useState(1);
    let history = useHistory();

    function onChangeIsRM(e) {
        setIsRM(e.target.value);
    }

    function onChangeIsCM(e) {
        setIsCM(e.target.value);
    }

    function onChangeIsActive(e) {
        setIsActive(e.target.value);
    }

    const onFinish = values => {
        let editResource = {
            "employeeId": form.getFieldValue('empid'),
            "displayNameTx": form.getFieldValue('empname'),
            "designation": form.getFieldValue('designation'),
            "joinedOn": form.getFieldValue('doj').format("YYYY-MM-DD"),
            "emailTx": form.getFieldValue('email'),
            "mobileTx": form.getFieldValue('mobile') ? form.getFieldValue('mobile') : null,
            "primarySkill": form.getFieldValue('pskill') ? form.getFieldValue('pskill') : null,
            "reportingManagerNm": form.getFieldValue('reporting') ? form.getFieldValue('reporting') : null,
            "locationId": form.getFieldValue('location') ? form.getFieldValue('location') : null,
            //"departmentId": form.getFieldValue('department') ? form.getFieldValue('department') : null,
            //"subDepartmentId": form.getFieldValue('subdepartment') ? form.getFieldValue('subdepartment') : null,
            //"divisionId": form.getFieldValue('division') ? form.getFieldValue('division') : null,
            "competency": form.getFieldValue('competency') ? form.getFieldValue('competency') : null,
            "isReportingManager": form.getFieldValue('reportingmgr'),
            "isCompetencyMgr": form.getFieldValue('competencymgr'),
            "activateYnNm": form.getFieldValue('active'),
            "roles": form.getFieldValue('roles'),
            "relievedOn": form.getFieldValue('doe') ? form.getFieldValue('doe').format("YYYY-MM-DD") : null,
            //"passwordTx": "Gemini@123",            
        }

        axios.put(API_URL + `/api/users/update/${props.match.params.id}`, editResource,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                message.success("Resource updated Successfully");
                form.resetFields();
                history.push('/master/orgstructure/manage')
            })
            .catch(error => {
                console.log(error);
                message.error("Error In creating Project", error)
            });
    }

    const getUserList = () => {
        axios.get(API_URL + '/api/users/getall',
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                setUsers(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getResourcebyId = () => {
        axios.get(API_URL + '/api/users/getuser/' + props.match.params.id,
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                let resource = response.data.data;
                let userRoles = [];
                if (resource.roles.length != 0) {
                    resource.roles.map((role, index) => {
                        userRoles = [...userRoles, role.id]
                    })
                }
                console.log(response.data.data);
                setIsRM(resource.isReportingManager)
                setIsCM(resource.isCompetencyMgr)
                form.setFieldsValue({
                    empid: resource.employeeId ? resource.employeeId : null,
                    empname: resource.displayNameTx ? resource.displayNameTx : null,
                    designation: resource.designation ? resource.designation : null,
                    doj: resource.joinedOn ? moment(resource.joinedOn) : null,
                    email: resource.emailTx ? resource.emailTx : null,
                    mobile: resource.mobileTx ? resource.mobileTx : null,
                    pskill: resource.primarySkill ? resource.primarySkill : null,
                    reporting: resource.reportingManagerNm ? resource.reportingManagerNm : null,
                    location: resource.locationId ? resource.locationId : null,
                    //department: resource.departmentId ? resource.departmentId : null,
                    //subdepartment: resource.subDepartmentId ? resource.subDepartmentId : null,
                    //division: resource.divisionId ? resource.divisionId : null,
                    competency: resource.competency ? resource.competency : null,
                    competencymgr: resource.isCompetencyMgr,
                    active: resource.activateYnNm,
                    roles: userRoles,
                    reportingmgr: resource.isReportingManager
                });
            })
            .catch(error => {
                console.log(error);
            });
    }
    
    const getLocations = () => {
        axios.get(API_URL + '/api/skillbase/locations/getAllLocations',
            {
                headers: {
                    'x-access-token': `${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                setLocations(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        //getDivisions();
        //getDepartments();
        //getSubDepartment();
        getUserList();
        getResourcebyId();
        getLocations();
    }, [])

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>Executive</Breadcrumb.Item>
                <Breadcrumb.Item><i className="icon icon-family" /> Org Structure &rarr; New Resource</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Widget>
                <Form
                    form={form}
                    initialValues={{ remember: true }}
                    name="prjdetails"
                    onFinish={onFinish}
                    layout="inline"
                    align="center"
                >
                    <Divider orientation="left">Resource</Divider>
                    <Row >
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Emp Id</h6>
                                <Form.Item
                                    name="empid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Emp ID',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Emp ID" />

                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <h6 align="left">Name</h6>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="empname"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter resource name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Name" />

                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Designation</h6>
                                <Form.Item
                                    name="designation"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Designation',
                                        },
                                    ]}
                                >
                                    <Input disabled={!Boolean(isActive)} placeholder="Designation" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">DOJ</h6>
                                <Form.Item
                                    name="doj"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter DOJ',
                                        },
                                    ]}
                                >
                                    <DatePicker format="DD-MM-YYYY" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Email</h6>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Mobile</h6>
                                <Form.Item
                                    name="mobile"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Mobile',
                                        },
                                    ]}
                                >
                                    <Input disabled={!Boolean(isActive)} placeholder="Mobile" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Primary Skill</h6>
                                <Form.Item
                                    name="pskill"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter primary skill',
                                        },
                                    ]}
                                >

                                    <Input placeholder="Primary Skill" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Reporting To</h6>
                                <Form.Item
                                    name="reporting"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Reporting To',
                                        },
                                    ]}
                                >

                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select a Value"
                                        optionFilterProp="children"
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            users.length ? users.map((user, index) => (
                                                <Option key={index} value={user.id}>{user.displayNameTx}</Option>
                                            )) : null
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Location</h6>
                                <Form.Item
                                    name="location"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Location',
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={!Boolean(isActive)}
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select a Value"
                                        optionFilterProp="children"
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {locations.length ? locations.map((location, index) => (
                                            <Option key={index} value={location.id}>{location.name}</Option>
                                        )) : null}

                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>

                    <Divider orientation="left">Competency</Divider>
                    <Row>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Competency</h6>
                                <Form.Item
                                    name="competency"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Competency',
                                        },
                                    ]}
                                >
                                    <Input disabled={!Boolean(isActive)} placeholder="Competency" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Is Competency Manager?</h6>
                                <Form.Item
                                    name="competencymgr"
                                >
                                    <RadioGroup disabled={!Boolean(isActive)} name="competencymgr" onChange={(e) => onChangeIsCM(e)} value={isCM}>
                                        <Radio value={false}>No</Radio>
                                        <Radio value={true}>Yes</Radio>
                                    </RadioGroup>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Is Reporting Manager?</h6>
                                <Form.Item
                                    name="reportingmgr"
                                >
                                    <RadioGroup disabled={!Boolean(isActive)} name="reportingmgr" onChange={(e) => onChangeIsRM(e)} value={isRM}>
                                        <Radio value={false}>No</Radio>
                                        <Radio value={true}>Yes</Radio>
                                    </RadioGroup>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <h6 align="left">Roles</h6>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="roles"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Roles',
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={!Boolean(isActive)}
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select a Value"
                                        optionFilterProp="children"
                                        mode="multiple"
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        <Option value={1}>Executive</Option>
                                        <Option value={2}>PMO</Option>
                                        <Option value={3}>Project Manager</Option>
                                        <Option value={4}>Employee</Option>
                                        <Option value={5}>Admin</Option>
                                        <Option value={6}>Sales</Option>
                                        <Option value={7}>Human Resources</Option>
                                        <Option value={8}>Travel Admin</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>

                    </Row>
                    <Divider orientation="left">Status</Divider>
                    <Row>
                        <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Is Active?</h6>
                                <Form.Item
                                    name="active"
                                >
                                    <RadioGroup name="active" onChange={(e) => onChangeIsActive(e)} value={isActive}>
                                        <Radio value={0}>No</Radio>
                                        <Radio value={1}>Yes</Radio>
                                    </RadioGroup>
                                </Form.Item>
                            </div>
                        </Col>
                        {isActive ? null : <Col lg={8} md={8} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <h6 align="left">Exit Date</h6>
                                <Form.Item
                                    name="doe"
                                    rules={[
                                        {
                                            required: !isActive,
                                            message: 'Enter Exit Date',
                                        },
                                    ]}
                                >
                                    <DatePicker />
                                </Form.Item>
                            </div>
                        </Col>}
                    </Row>

                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={24} xs={24} >
                            <div className="gx-form-row0">
                                <Form.Item>
                                    <Button type="primary" className="add-btn" htmlType="submit">Submit</Button>
                                    <Button type="primary" className="add-btn gx-mr-2" onClick={() => history.push('/master/orgstructure/manage')}>Back</Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Widget>
        </>
    );
};

export default EditResource;